
import { useState } from 'react';
import Button from '@mui/material/Button';

interface MenuProps {
  route: any;
}

const Menu = (menu: MenuProps) => {

  const [selected, setSelected] = useState<string>('mission');

  const redirect = (path: string) => {
    setSelected(path);
    menu.route(path);
  }

  const selectedStyle = {backgroundColor: '#db7901'};

  return (
    <div className="Menu">
      {/* <Button 
        variant="contained" 
        style={selected == "mission" ? selectedStyle : {}} 
        onClick={() => {redirect("mission")}}
      >Mission</Button>
      <Button 
        variant="contained" 
        style={selected == "history" ? selectedStyle : {}} 
        onClick={() => {redirect("history")}}
      >History</Button>
      <Button 
        variant="contained" 
        style={selected == "numbers" ? selectedStyle : {}} 
        onClick={() => {redirect("numbers")}}
      >Numbers</Button> */}
    </div>
  );
};

export default Menu;