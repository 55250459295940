import { useState, useEffect } from "react"

interface Props {
  end: number
}

const Timer = (props: Props) => {
  const [timer, setTimer] = useState("00:00:00")

  const getTimeRemaining = (e: any) => {
    const total = Date.parse(e) - Date.parse(new Date().toString())
    const seconds = Math.floor((total / 1000) % 60)
    const minutes = Math.floor((total / 1000 / 60) % 60)
    const hours = Math.trunc(total / 1000 / 60 / 60)
    return {
      total,
      hours,
      minutes,
      seconds,
    }
  }

  const startTimer = (e: any) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e)

    if (total >= 0) {
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      )
    }
  }

  const clearTimer = (e: any) => {
    setInterval(() => {
      startTimer(e)
    }, 1000)
  }

  const getDeadTime = () => {
    let deadline = new Date(props.end)
    return deadline
  }

  useEffect(() => {
    clearTimer(getDeadTime())
  }, [props.end])

  return (
    <span>
      <span>{timer}</span>
    </span>
  )
}

export default Timer