import { useEffect, useState, useRef } from "react";
import { useWallet, WalletStatus, useConnectedWallet } from "@terra-money/wallet-kit";
import { useWallet as useWalletGalaxy, WalletStatus as WalletStatusGalaxy, useConnectedWallet as useConnectedWalletGalaxy, useLcdClient as useLcdClientGalaxy } from "@hexxagon/wallet-kit";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import WalletInfo from "./wallet/WalletInfo";
import Button from '@mui/material/Button';
import ExploreIcon from '@mui/icons-material/Explore';
import DescriptionIcon from '@mui/icons-material/Description';
import { Row } from "./layout/Grid";
import { Col } from "./layout/Grid";
import { LinearProgress } from "@mui/material";
import { ticketPrice } from "contract/config";
import { rocketContract } from "contract/config";
import { sizeDrandBlocks } from "contract/config";
import { Coins } from "@terra-money/feather.js";
import { Coins as CoinsGalaxy } from "@hexxagon/feather.js";
import Timer from "./Timer";
import * as execute from "../contract/execute";
import * as executeGalaxy from "../contract/executeGalaxy";
import axios from "axios";

const Mission = () => {
  const walletStation = useWallet();
  const { status } = walletStation;
  const walletGalaxy = useWalletGalaxy();
  const { status: statusGalaxy } = walletGalaxy;

  const inputQtd = useRef<HTMLInputElement>(null);

  const connectedStation = useConnectedWallet();
  const connectedGalaxy = useConnectedWalletGalaxy();

  const [connected, setConnected] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [statusReq, setStatusReq] = useState("Success");
  const [message, setMessage] = useState<any>("");
  const [mission, setMission] = useState<any>();
  const [configs, setConfigs] = useState<any>();
  const [player, setPlayer] = useState<any>();
  const [total, setTotal] = useState(ticketPrice);
  const [luncBalance, setLuncBalance] = useState<number>(0);
  const [ustcBalance, setUstcBalance] = useState<number>(0);

  const drandGenesisTime = 1595431050;
  const currentTimespamp = Math.floor(Date.now() / 1000);

  function blocksRemaining(missionDrand: number) {
    const missionTime = missionDrand * 30;
    const timeRemaining = missionTime - (currentTimespamp - drandGenesisTime);
    if (timeRemaining < 0) {
      return 0;
    }
    return Number((timeRemaining / 30).toFixed(0));
  }

  function timeRemaining(missionDrand: number) {
    const missionTime = missionDrand * 30;
    const timeRemaining = missionTime - (currentTimespamp - drandGenesisTime);
    return (currentTimespamp + timeRemaining) * 1000;
  }

  const formatter = new Intl.NumberFormat('en-US', {
    signDisplay: 'never',
  });

  const handleDilogClose = () => {
    setOpenDialog(false);
  }

  const buyTickets = async () => {
    const qtdTickets = inputQtd.current!.value === "" ? 1 : parseInt(inputQtd.current!.value);
    if ( qtdTickets <= 0 ) {
      setStatusReq("Error");
      setMessage("Please, inform the quantity of tickets to buy");
      setOpenDialog(true);
      return;
    }
    executeTX({ buy_tickets: { qtd: qtdTickets } }, "buy_tickets", qtdTickets);
  }

  const executeTX = async (msg: any, action: string, qtdTickets: number) => {
    if (!connected) {
      setStatusReq("Error");
      setOpenDialog(true);
      setMessage("Connect your wallet");
      return;
    }

    try {

      setStatusReq("Loading");
      setOpenDialog(true);

      // close loading timeout
      setTimeout(() => {
        setOpenDialog(false);
      }, 20000);
      
      if (connectedStation) {

        if (action == "buy_tickets") {

          const resp = await execute.buy(walletStation, connectedStation, "columbus-5", new Coins({'uluna': qtdTickets * ticketPrice}), qtdTickets);
          
          if (resp.txhash !== undefined) {
            setStatusReq("Success");
            setMessage(resp.txhash);
          } else {
            setStatusReq("Error");
            setMessage(resp.raw_log);
          }
        }

        if (action == "next_round") {

          const resp = await execute.next(walletStation, connectedStation, "columbus-5", new Coins(), msg);
          
          if (resp.txhash !== undefined) {
            setStatusReq("Success");
            setMessage(resp.txhash);
          } else {
            setStatusReq("Error");
            setMessage(resp.raw_log);
          }
        }
      }

      if (connectedGalaxy) {
      
        if (action == "buy_tickets") {

          const resp = await executeGalaxy.buy(walletGalaxy, connectedGalaxy, "columbus-5", new CoinsGalaxy({'uluna': qtdTickets * ticketPrice}), qtdTickets);
          
          if (resp.txhash !== undefined) {
            setStatusReq("Success");
            setMessage(resp.txhash);
          } else {
            setStatusReq("Error");
            setMessage(resp.raw_log);
          }
        }
      }

      setOpenDialog(true);

    } catch (error: any) {
      setStatusReq("Error");
      setMessage(error.response.data.message);
      
      if ( error.response.data.message.includes("failed to execute message; message index: 0: Waiting next round") ) {
        setMessage("Waiting to start the next mission");
      }
      if ( error.response.data.message.includes("failed to execute message; message index: 0: Closed bets, wait for the processing to open the new round") ) {
        setMessage("Closed, wait for the processing to open the new mission");
      }
      if ( error.response.data.message.includes("failed to execute message; message index: 0: Insufficient funds") ) {
        setMessage("Insufficient funds");
      }
      if ( error.response.data.message.includes("failed to execute message; message index: 0: Funds more than enough") ) {
        setMessage("Funds more than enough");
      }
      if ( error.response.data.message.includes("failed to execute message; message index: 0: Insufficient time for draw") ) {
        setMessage("Insufficient time for draw");
      }
      if ( error.response.data.message.includes("rpc error: code = NotFound desc = account")) {
        setMessage("Account does not have enough LUNC to pay transaction fee");
      }
      
      
      setOpenDialog(true);
    }
  }

  const exploreAction = () => {
    setStatusReq("Explore");
    setMessage(
      <div style={{fontSize: "12px"}}>
        <p><b>HOW-TO-PLAY</b></p>
        <p style={{textAlign: "justify"}}>
        Select how much rocket tickets you want to participate of the exploration mission race. The more rickets you have, the higher your chances of winning. Each mission has a set duration, and at the end, only one rocket finds the prize asteroid and earn the tokens.
        </p>
        <p><b>DISTRIBUTION RULES:</b></p>
        <p style={{textAlign: "justify"}}>
          <b>1- Until 100M LUNC accumulation goal is NOT reached:</b>
          <br/>Winner Prize: 30%
          <br/>Burn: 0%
          <br/>Project: 10%
          <br/>Next mission: 60%
        </p>
        <p style={{textAlign: "justify"}}>
          <b>2- Once the goal of 100M LUNC reached:</b>
          <br/>Winner Prize: 30% LUNC + 30% TRIT
          <br/>Burn: 30%
          <br/>Project: 10%
        </p>
      </div>
    );
    setOpenDialog(true);
  }

  const wpAction = () => {
    window.open("/wpaper.pdf", "_blank");
  }

  const startAction = async () => {
    try {
      const resp = await axios.get("https://drand.cloudflare.com/public/" + mission.drand);
      const psign = Buffer.from(resp.data.previous_signature, "hex").toString("base64");
      const sign = Buffer.from(resp.data.signature, "hex").toString("base64");

      executeTX({ next_round: { previous_signature: psign, signature: sign, ticket_price: ticketPrice.toString() } }, "next_round", 0);
    } catch (error: any) {
      if (error.code == "ERR_BAD_REQUEST") {
        setStatusReq("Error");
        setMessage(
          <>
            <p>Drand not found yet</p>
            <br/>
            <a href={"https://drand.cloudflare.com/public/" + mission.drand} target="_blank">Drand Code: {mission.drand}</a>
          </>
        );
        setOpenDialog(true);
      }
    }
  }

  useEffect(() => {
    if (status === WalletStatus.CONNECTED || statusGalaxy === WalletStatusGalaxy.CONNECTED) {
      
      let myWallet = "";

      if (connectedStation) {
        myWallet = connectedStation.addresses['columbus-5'];
      }

      if (connectedGalaxy) {
        myWallet = connectedGalaxy.addresses['columbus-5'];
      }

      setConnected(true);
      const missions = async () => {
        const resp = await axios.get("https://terra-classic-lcd.publicnode.com/cosmwasm/wasm/v1/contract/"+ rocketContract +"/smart/eyJnZXRfYWN0aXZlX3JvdW5kIjp7fX0=");
        setMission(resp.data.data);
      }
      missions();
      const configs = async () => {
        const resp = await axios.get("https://terra-classic-lcd.publicnode.com/cosmwasm/wasm/v1/contract/"+ rocketContract +"/smart/eyJnZXRfY29uZmlnIjp7fX0=");
        setConfigs(resp.data.data);

        const query = btoa("{ \"get_bet\" :{ \"round\": \""+ resp.data.data.count_round +"\", \"owner\": \""+ myWallet +"\"} }");
        try {
          const respPlayer = await axios.get("https://terra-classic-lcd.publicnode.com/cosmwasm/wasm/v1/contract/"+ rocketContract +"/smart/"+ query);
          setPlayer(respPlayer.data.data);
        } catch (error: any) {
          
        }
      }
      configs();
    } else {
      setConnected(false);
    }
  }, [status, statusGalaxy]);

  return (
    <div>
      <div style={{textAlign: "right"}}>
        {connectedStation && connectedStation.addresses['columbus-5'] == "terra13xnslxvljjvy8tcz58rc6qyva20xsqsevph76j" && (
          <>
            <span className="btnExplore" onClick={startAction}>
              Start <span className="exploreIcon"><ExploreIcon /></span>
            </span>
            &nbsp;
          </>
        )}
        <span className="btnExplore" onClick={exploreAction}>
          Explore <span className="exploreIcon"><ExploreIcon /></span>
        </span>
        &nbsp;
        <span className="btnWP" onClick={wpAction}>
          Light Paper <span className="exploreIcon"><DescriptionIcon /></span>
        </span>
      </div>
      {mission && configs && blocksRemaining(mission.drand) > 0 && (
        <div className="missionBox">
          <Row>
            <Col>
              {mission && (
                <>
                  <Row>
                    <Col>
                      <a href="https://drand.love" target="_blank" style={{textDecoration: "none"}}>
                        <div className="tagBlack">Launch in <b><Timer end={timeRemaining(mission.drand)} /></b></div>
                      </a>
                    </Col>
                  </Row>
                  <Row><Col><LinearProgress color="warning" variant="determinate" value={100 - (((blocksRemaining(mission.drand))*100)/sizeDrandBlocks)} /></Col></Row>
                </>
              )}
              {player && (
                <Row>
                  <Col>
                    <div className="missionTranparentField">
                      Your Tickets
                      <div className="numbers">{player.qtd}</div>
                    </div>
                  </Col>
                  <Col>
                    <div className="missionTranparentField">
                      Estimated Success Rate
                      <div className="numbers">{((player.qtd / (configs.count_ticket - mission.ini_ticket)) * 100).toFixed(4)} %</div>
                    </div>
                  </Col>
                </Row>
              )}
              {!player && (
                <Row>
                  <Col>
                    <div className="missionTranparentField">
                      Your Tickets
                      <div className="numbers">0</div>
                    </div>
                  </Col>
                  <Col>
                    <div className="missionTranparentField">
                      Estimated Success Rate
                      <div className="numbers">0 %</div>
                    </div>
                  </Col>
                </Row>
              )}
              <WalletInfo ulunaBalance={setLuncBalance} uusdBalance={setUstcBalance} />
              <Row>
                <Col>
                  <div style={{textAlign: "center"}}>Quantity</div>
                  <div className="inputQtdTicket">
                    <input
                      ref={inputQtd}
                      name="qtd"
                      id="qtd"
                      placeholder="1"
                      min={1}
                      max={100000}
                      step={1}
                      type="number"
                      style={{width: "136px", position: "relative", left: "10px", textAlign: "center"}} 
                      onChange={(e) => {
                        setTotal(parseInt(e.target.value) * ticketPrice);
                      }}
                    />
                  </div>
                </Col>
                <Col span={3}>
                <div style={{textAlign: "center"}}>You pay</div>
                  <div className="inputQtdTicket" style={{height: "32px", cursor: "auto"}}>
                    <div style={{padding: "4px", fontSize: "18px", fontWeight: "normal", textAlign: "center", color: "#999"}}>
                      {total / 1000000} <span style={{fontSize: "11px"}}>LUNC</span>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col><Button className="buy" onClick={buyTickets}>Buy Tickets</Button></Col>
              </Row>
              <Row>
                <Col span={2}><div className="missionField">Mission</div></Col>
                <Col><div className="missionValue">{configs.count_round.toString().padStart(4, '0')}</div></Col>
              </Row>
              <Row>
                <Col span={2}><div className="missionField">Accumulated</div></Col>
                <Col><div className="missionValue">{formatter.format(mission.amount / 1000000)} <span style={{fontSize: "10px"}}>LUNC</span></div></Col>
              </Row>
              <Row>
                <Col span={2}><div className="missionField">Burn</div></Col>
                <Col><div className="missionValue">{formatter.format(mission.amount * 0.3 / 1000000)} <span style={{fontSize: "10px"}}>LUNC</span></div></Col>
              </Row>
              <Row>
                <Col>
                  <div className="missionField">
                    Estimated Prize 
                    <div className="blocks">{formatter.format((mission.amount * 0.6) / 1000000)} <span style={{fontSize: "22px"}}>LUNC</span></div>
                    <span style={{fontSize: "9px", color: "#db7901"}}> * Minimum required accumulation 100M LUNC </span> 
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      )}

      {mission && configs && blocksRemaining(mission.drand) <= 0 && (
        <div className="missionBox">
          <div className="completed">Mission completed processing results...</div>
          <Row>
            <Col><div className="missionField">Mission</div></Col>
            <Col><div className="missionValue">{configs.count_round.toString().padStart(4, '0')}</div></Col>
          </Row>
          <Row>
            <Col><div className="missionField">Accumulated in LUNC</div></Col>
            <Col><div className="missionValue">{mission.amount / 1000000}</div></Col>
          </Row>
          <Row>
            <Col><div className="missionField">Prize LUNC</div></Col>
            <Col><div className="missionValue">{(mission.amount / 1000000) * 0.3}</div></Col>
          </Row>
          <Row>
            <Col><div className="missionField">Prize TRIT</div></Col>
            <Col><div className="missionValue">{"?"}</div></Col>
          </Row>
          <Row>
            <Col><div className="missionField">Burn</div></Col>
            <Col><div className="missionValue">{(mission.amount / 1000000) * 0.25}</div></Col>
          </Row>
          <Row>
            <Col><div className="missionField">Code Drand</div></Col>
            <Col><div className="missionValue"><a href={"https://api.drand.sh/public/" + mission.drand} target="_blank">{mission.drand}</a></div></Col>
          </Row>
        </div>
      )}

      {!connectedStation && !connectedGalaxy && (
        <div className="missionBox">
          <div className="completed">Connect your wallet to play...</div>
        </div>
      )}
      
      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{statusReq}</DialogTitle>
        <DialogContent>
          <div className="dialogContent">
            {statusReq === "Referrer Link" && (
              <div className="textRefLink">{message}</div>
            )}
            {statusReq === "Success" && (
              <>
                <div>Transaction completed</div>
                <br/>
              </>
            )}
            {statusReq === "Stake Details" && (
              <>{message}</>
            )}
            {statusReq === "Explore" && (
              <>{message}</>
            )}
            <div style={{ fontSize: '10px' }}>
              {statusReq === "Success" && (
                <a href={"https://finder.terra.money/classic/tx/" + message} target="blank">
                  Finder TX: {message}
                </a>
              )}
              {statusReq === "Error" && (
                <span>{message != "" ? message : "Transaction canceled"}</span>
              )}
              {statusReq === "Loading" && (
                <span><LinearProgress /></span>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          {statusReq != "Loading" && (
            <Button onClick={handleDilogClose} autoFocus>Ok</Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Mission;