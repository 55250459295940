import "./Footer.css";
import { rocketContract } from "contract/config";

const Footer = () => {

  return (
    <div className="footer">
      <div className="contractInfo">
        Contract on Terra Classic <a href={"https://finder.terra-classic.hexxagon.io/mainnet/address/" + rocketContract} target="_blank">{rocketContract}</a>
      </div>
      <div className="disclaimer">Tritium Rocket makes no representation or warranty that the use of this dApp is appropriate in every country or jurisdiction, and access to this Site from countries or jurisdictions where its content is illegal is prohibited. Users who choose to access the dApp are responsible for compliance with their applicable local laws, rules and regulations concerning tort, lotteries, giveaways, raffles and/or gambling. The User shall hold the Author harmless of all costs and/or damages, including (but not limited to), any interest, damages, penalties and legal expenses and fees that the User may incur as a result of non-compliance with any laws or acts that may arise from using the dApp. This site may contain links to third party content, which Tritium Rocket do not warrant, endorse, or assume liability for.</div>
      <div className="copy">Copyright © 2024 All Rights Reserved by Terra Tritium | <b>Version 0.1.2 Beta</b></div>
    </div>
  );
};

export default Footer;