export const columbus5 = {
  "columbus-5": {
    lcd: "https://lcd.terra.dev",
    chainID: "columbus-5",
    gasAdjustment: 3,
    gasPrices: {
      uusd: 0.9,
      uluna: 48.325
    },
    prefix: "terra",
    isClassic: true
  }
}

export const maintenance = false
export const ulunaPrice = 0.0589
export const uusdPrice = 13.697
export const tritTokenContract = "terra1g6fm3yu79gv0rc8067n2nnfpf0vks6n0wpzaf4u7w48tdrmj98zsy7uu00"
export const tritSwapContract = "terra1efwhly5plrjxqzdmhxh2kr90ylw3jef6yqepha3c4xtuvzs3u4sscdztzl"
export const tritReferenceWallet = "terra1hl5yallqzzs47z6ns7cptzxrsaqe2f3szsh25g"
export const nftOriginsContract = "terra18sj5hluytww5hmy4teqsr4a7qr66pcd2x3qm9ns96ucpkdyserpszlx3qw"
export const tritStakeContract = "terra12h5yexqr39g7025z02fwz60ek5qyuy3tpuytuc8w7x5mlj2ms3fq0psx76"
export const nftRequire = false
export const rocketContract = "terra1rhtrggcsqc7smk88cs3rux7pax6skj7zzra8fp09esdjck63gm0qhdv5ux"
export const ticketPrice = 25000000000
export const sizeDrandBlocks = 43200

// Execution for config [terra1efwhly5plrjxqzdmhxh2kr90ylw3jef6yqepha3c4xtuvzs3u4sscdztzl]
// {"config": {
//   "trit_contract": "terra1g6fm3yu79gv0rc8067n2nnfpf0vks6n0wpzaf4u7w48tdrmj98zsy7uu00",
//   "treasure": "terra1vk2r09denldx79kq2f2t0wkgvxdm6c2r7fpgh5",
//   "lunc_price": "0.0589",
//   "ustc_price": "13.697",
//   "owner_rate": "0.82",
//   "treasure_rate": "0.15",
//   "referral_rate": "0.03"
// }}

// Query Balance
// {"balance": {"address":"terra1efwhly5plrjxqzdmhxh2kr90ylw3jef6yqepha3c4xtuvzs3u4sscdztzl"}}