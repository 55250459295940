import { ConnectWallet } from "components/wallet/ConnectWallet";

const TopBar = () => {

  return (
    <div className="TopBar">
      <div className="TopBarContent">
        <ConnectWallet />
      </div>
    </div>
  );
};

export default TopBar;